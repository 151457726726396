<template>
  <el-descriptions :column="1">
    <el-descriptions-item label="报备次数">{{ item.manyTimes }}</el-descriptions-item>
    <el-descriptions-item label="系统编号">
      <el-checkbox
        :label="item.id"
        size="large"
        v-if="isCanCheck"
      ></el-checkbox>
      <span v-else>{{ item.id }}</span>
    </el-descriptions-item>
    <el-descriptions-item label="报备单号">{{
      item.hNumber
    }}</el-descriptions-item>
    <el-descriptions-item label="公司名称">{{
      item.company
    }}</el-descriptions-item>
    <el-descriptions-item label="带看人"
      >{{ item.daikanName }}/{{ item.daikanMobile }}</el-descriptions-item
    >
    <el-descriptions-item label="报备人"
      >{{ item.consumerName }}/{{ item.consumerMobile }}</el-descriptions-item
    >
    <el-descriptions-item label="客户姓名">{{
      item.clientName
    }}</el-descriptions-item>
    <el-descriptions-item label="客户性别">{{ item.sex }}</el-descriptions-item>
    <el-descriptions-item label="客户号码">{{
      item.clientMobile
    }}</el-descriptions-item>
    <el-descriptions-item label="带看项目">{{
      item.estateName
    }}</el-descriptions-item>
    <el-descriptions-item label="到案人数">{{
      item.dueNum
    }}</el-descriptions-item>
    <el-descriptions-item label="到案时间">{{
      item.dueTime
    }}</el-descriptions-item>
    <el-descriptions-item label="报备备注">{{
      item.comment
    }}</el-descriptions-item>
    <el-descriptions-item label="报备时间">{{
      item.creationTime
    }}</el-descriptions-item>
    <el-descriptions-item label="状态">
      <el-tag type="warning" :underline="false" v-if="item.curStatus == 0">{{
        item.cur_status_text
      }}</el-tag>
      <el-tag
        type="info"
        size="small"
        effect="dark"
        v-else-if="item.curStatus == 1"
        >{{ item.cur_status_text }}</el-tag
      >
      <el-tag
        type="success"
        size="small"
        effect="dark"
        v-else-if="item.curStatus == 2"
        >{{ item.cur_status_text }}</el-tag
      >
      <el-tag
        type="danger"
        size="small"
        effect="dark"
        v-else-if="item.curStatus == 3"
        >{{ item.cur_status_text }}</el-tag
      >
      <el-tag :underline="false" effect="dark" v-else>{{
        item.cur_status_text
      }}</el-tag>
      <el-tag type="danger" size="small" effect="dark" v-if="item.unknown"
        >结果未知</el-tag
      >
    </el-descriptions-item>
    <el-descriptions-item label="处理人"
      >{{ item.modify_name }} {{ item.modifiedTime }}

      <span v-if="item.isAcData"
        >{{ item.kefu_name }}({{ item.kefu_mobile }})</span
      >
    </el-descriptions-item>
    <el-descriptions-item label="处理结果">{{
      item.resultContent
    }}</el-descriptions-item>
    <el-descriptions-item label="二维码">
      <el-image
        v-if="item.qrCodeUrl"
        :src="item.qrCodeUrl"
        fit="cover"
        :initial-index="1"
        :preview-src-list="[item.qrCodeUrl]"
        style="width: 50px; height: 50px; margin-right: 10px"
      ></el-image>
      <el-button
        type="info"
        size="small"
        icon="upload"
        v-if="item.estate.isGetQRCode"
        @click="onUploadQrCode(item)"
        >上传二维码</el-button
      >
      <span v-else style="display: inline-block">项目无二维码</span>
    </el-descriptions-item>
    <el-descriptions-item label="附件">
      <!-- <el-button
        v-if="item.attUrls && item.attUrls.length"
        type="primary"
        link
        icon="view"
        @click="onPreviewAtt(item)"
        >查看附件({{ item.attUrls.length }})</el-button
      > -->
      <div class="att_wrp" v-if="item.attUrls && item.attUrls.length">
        <el-image
          v-for="(url, index) in item.attUrls"
          :key="index"
          :src="url"
          fit="cover" 
          :initial-index="index"
          :preview-src-list="item.attUrls"
          style="width: 50px; height: 50px; margin-right: 10px"
        ></el-image>
      </div>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
    isCanCheck: Boolean,
  },
  emits: ["PreviewAtt", "UploadQrCode"],
  watch: {},
  created () {
    
  },
  methods: {
    /**
     * 查看附件
     * @param {*} item
     */
    onPreviewAtt(item) {
      this.$emit("PreviewAtt", item);
    },

    /**
     *
     * @param {*} item
     */
    onUploadQrCode(item) {
      this.$emit("UploadQrCode", item);
    },
  },
};
</script>

<style scoped>
/* 附件 */
.att_wrp {
  display: flex;
  flex-wrap: wrap;
}
</style>
